import React from 'react';

import PostBody from '../../02-molecules/PostBody/PostBody';

import './BookPost.css';

const BookPost = ({
  children,
  embeddedImagesLocal,
  embeddedImagesRemote,
  tableOfContents,
  title,
}) => (
  <article className="BookPost">
    <header className="BookPost__header">
      <h1>{title}</h1>
    </header>
    <PostBody
      embeddedImagesLocal={embeddedImagesLocal}
      embeddedImagesRemote={embeddedImagesRemote}
      tableOfContents={tableOfContents}
    >
      {children}
    </PostBody>
  </article>
);

export default BookPost;
