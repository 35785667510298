import React, { useEffect } from 'react';

const removeScript = (script) => {
  if (script) {
    document.body.removeChild(script);
  }
};

const withStructuredData = (Component) => (props) => {
  const {
    pageContext: { structuredDataPath },
  } = props;

  useEffect(() => {
    let script;
    if (structuredDataPath) {
      import(`../_content/${structuredDataPath}`).then((importedData) => {
        if (importedData) {
          removeScript(script);
          script = document.createElement(`script`);
          script.type = `application/ld+json`;
          script.innerText = JSON.stringify(importedData.default);
          document.body.appendChild(script);
        }
      });
    }
    return () => {
      removeScript(script);
    };
  }, [structuredDataPath]);

  return <Component {...props} />;
};

export default withStructuredData;
