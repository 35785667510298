import React from 'react';
import { graphql } from 'gatsby';
import { Meta } from 'react-head';

import BookPost from '../../03-organisms/BookPost/BookPost';
import SEO from '../../01-atoms/SEO/SEO';
import withStructuredData from '../../../HOCs/withStructuredData';

const Page = ({ data, location, pageContext: { openGraphImageUrl } }) => {
  const {
    post: {
      body,
      frontmatter: {
        description,
        embeddedImagesLocal,
        embeddedImagesRemote,
        meta_description_override,
        meta_title_override,
        title,
      },
      tableOfContents,
    },
  } = data;

  return (
    <>
      <SEO
        description={meta_description_override || description}
        location={location}
        title={meta_title_override || title}
      />

      <Meta content="article" name="og:type" />

      {!!openGraphImageUrl && (
        <>
          <Meta content={openGraphImageUrl} name="twitter:image" />
          <Meta content={openGraphImageUrl} name="og:image" />
        </>
      )}

      <BookPost
        embeddedImagesLocal={embeddedImagesLocal}
        embeddedImagesRemote={embeddedImagesRemote}
        tableOfContents={tableOfContents}
        title={title}
      >
        {body}
      </BookPost>
    </>
  );
};

export const query = graphql`
  query($internalPagePath: String) {
    post: mdx(fields: { path: { eq: $internalPagePath } }) {
      body
      fields {
        readingTime {
          text
        }
      }
      frontmatter {
        description
        embeddedImagesRemote {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        meta_description_override
        meta_title_override
        title
      }
      tableOfContents
    }
  }
`;

export default withStructuredData(Page);
